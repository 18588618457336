<template>
	<div class="garden-list-page">
		<div class="search-box">
			<a-row class="search-row">
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">类型：</a-col>
						<a-col :span="18" class="value-box">
							<a-select v-model="park_type_id" class="a-select" placeholder="请选择" @change="searchList">
								<a-select-option :value="item.park_type_id" v-for="(item,index) in ParkTypeList" :key="item.park_type_id">
									{{item.park_type_name}}
								</a-select-option>
							  </a-select>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">名称：</a-col>
						<a-col :span="18" class="value-box">
							<a-input placeholder="请输入" v-model="park_name"/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="7">
					<a-row class="">
						<a-col :span="6" class="label-box">状态：</a-col>
						<a-col :span="18" class="value-box">
							<a-select v-model="status" class="a-select" placeholder="请选择">
								<a-select-option :value="1">
								 启用
								</a-select-option>
								<a-select-option :value="-1">
								  关闭
								</a-select-option>
							  </a-select>
						</a-col>
					</a-row>
				</a-col>
				<a-col class="btn-box">
					<a-button type="default" @click="resetTiao">重置</a-button>
					<a-button type="primary" @click="searchList">查询</a-button>
				</a-col>
			</a-row>
			
		</div>
		<div class="color-block"></div>
		<div class="list-table-box">
			<div class="control-box">
				<a-button type="primary" @click="toDetail(0)">
					<a-icon type="plus-circle" />
					添加
				</a-button>
			</div>
			<div class="ant-table-wrapper">
                <div class="ant-spin-nested-loading">
                    <div class="ant-spin-container">
                        <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                            <div class="ant-table-content"><!---->
                                <div class="ant-table-body">
                                    <table class="">
                                        <colgroup>
                                            <col>
                                            <col style="width: 148px; min-width: 148px;">
                                            <col>
                                            <col>
                                            <col>
                                            <col style="width: 65px; min-width: 65px;">
                                            <col style="width: 105px; min-width: 105px;">
                                            <col style="width: 140px; min-width: 140px;">
                                        </colgroup>
                                        <thead class="ant-table-thead">
                                            <tr>
                                                <th key="park_type_name" class="">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">类型</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="header_img" class="ant-table-row-cell-break-word">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">展示图</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="park_name" class="">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">名称</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="3" class="">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">行政地区</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="tag_name" class="ant-table-column-has-actions ant-table-column-has-sorters">
                                                <span class="ant-table-header-column">
                                                    <div class="ant-table-column-sorters">
                                                        <span class="ant-table-column-title">价格</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="is_show" class="ant-table-row-cell-break-word">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">状态</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="is_recommend" class="ant-table-row-cell-break-word">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">热门推荐</span>
                                                    </div>
                                                </span>
                                            </th>
                                            <th key="operation" class="ant-table-row-cell-break-word ant-table-row-cell-last">
                                                <span class="ant-table-header-column">
                                                    <div>
                                                        <span class="ant-table-column-title">操作</span>
                                                        <span class="ant-table-column-sorter"></span>
                                                    </div>
                                                </span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="ant-table-tbody"
                                            @end="onEnd" 
                                            v-model="list" 
                                            :animation="300" 
                                            is='vuedraggable'
                                            style="width:100%;
                                                display: table-row-group;
                                                vertical-align: middle;
                                                border-color: inherit;">
                                            <tr
                                            style="cursor:grab"
                                             class="ant-table-row ant-table-row-level-0" 
                                             :data-row-key="index" v-for="(item,index) in list" :key="item.park_id">
                                                <td class="">政府园区</td>
                                                <td class="ant-table-row-cell-break-word">
                                                    <div class="head-img-box">
                                                        <img :src="$imglink+item.header_img">
                                                    </div>
                                                </td>
                                                <td class="">{{item.park_name}}</td>
                                                <td class="">{{item.pro + '>' + item.city + '>' + item.area}}</td>
                                                <td class="ant-table-column-has-actions ant-table-column-has-sorters">{{item.tag_name}}</td>
                                                <td class="ant-table-row-cell-break-word">
                                                    <div class="qiy act"> {{item.is_show == 1 ? '启用' : '未启用'}} </div>
                                                </td>
                                                <td class="ant-table-row-cell-break-word">
                                                    <a-switch :style="{'pointer-events': reIng ? 'none' : 'auto'}" :checked="item.is_recommend == 1" @change="recoChange(item.park_id,item.is_recommend)" />
                                                </td>
                                                <td class="ant-table-row-cell-break-word">
                                                    <div class="control-box">
                                                        <a-button size="small" type="primary" @click="toEdit(item)">编辑</a-button>
                                                        <a-popconfirm title="确认删除该园区" ok-text="是" cancel-text="否" @confirm="deleteItem(item)">
                                                            <a-button size="small" type="danger">删除</a-button>
                                                        </a-popconfirm>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!---->
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import vuedraggable from 'vuedraggable'
	export default {
        components:{vuedraggable},
		data() {
			let columns = [{
				title: '类型',
				key: 'park_type_name',
				dataIndex: 'park_type_name'
			},{
				title: '展示图',
				dataIndex: 'header_img',
				width: '148px',
				scopedSlots: { customRender: 'header_img' }
			},{
				title: '名称',
				key: 'park_name',
				dataIndex: 'park_name'
			},{
				title: '行政地区',
				customRender: (text, record, index) => `${record.pro}>${record.city}>${record.area}`
			},{
				title: '价格',
				key: 'tag_name',
				dataIndex: 'tag_name',
				defaultSortOrder: 'descend',
				sortOrder: false,
				sorter: (a, b) => a.low_price - b.low_price,
				sortDirections: ['descend', 'ascend']
			},
			{
				title: '状态',
				dataIndex: 'is_show',
				scopedSlots: { customRender: 'is_show' },
				width: '65px'
			},
			{
				title: '热门推荐',
				key: 'is_recommend',
				scopedSlots: { customRender: 'is_recommend' },
					width: '105px'
			},{
				title: '操作',
				dataIndex: 'operation',
				scopedSlots: { customRender: 'operation' },
				width: '140px'
			}]
			return {
				dataLoading: false,
				columns,
				list: [],
				page:{
					current: 1,
					total: 0
				},
				park_type_id: undefined,
				park_name: '',
				status: undefined,
				ParkTypeList:[],
				showIng: false,
				reIng: false
			}
		},
		computed: {},
		created() {
			this.getParkTypeList()
			this.getParkList()
		},
		mounted() {
			
		},
		methods:{
			searchList() {
				let t = this;
				t.page.current = 1
				t.getParkList()
			},
			getParkTypeList() {
				let t = this
				t.$post('ParkTypeList',{limit: 9999}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.ParkTypeList = data.list
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			getParkList() {
				let t = this
				let data ={
					park_type_id: t.park_type_id || '',
					park_name: t.park_name,
					is_show: t.status || '',
					page: t.page.current,
                    is_recommend: 1,
                    limit: 99999
				}
				console.log(data)
				
				t.$post('ParkList',data).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
						t.page.total = data.total
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			toDetail(type) {
				this.$router.push({path: '/gardenDetail',query: {type: type}})
			},
			toEdit(item) {
				this.$router.push({path: '/gardenDetail', query:{id: item.park_id}})
			},
			deleteItem(item) {
				let t = this
				t.$post('parkDelete',{park_id: item.park_id}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success('删除成功',1.5)
					    t.searchList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			listChange(e) {
				this.page.current = e.current
				this.getParkList();
			},
			resetTiao() {
				this.park_type_id = undefined
				this.park_name = ''
				this.status = undefined
				// this.searchList()
			},
			toggleShow(id,is_show) {
				let t = this
				if(t.showIng) {
					return 
				}else{
					t.showIng = true
				}
				t.$post('parkFabu',{park_id: id}).then(res => {
					t.showIng = false
					let { code, data, msg } = res
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用'
						
						console.log(str)
 						t.$message.success(str,1.5)
					    t.getParkList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.showIng = false
				})
			},
			recoChange(id,is_show) {
				let t = this
				if(t.reIng) {
					return 
				}else{
					t.reIng = true
				}
				t.$post('parkRecommend',{park_id: id}).then(res => {
					t.reIng = false
					let { code, data, msg } = res
					if (code == 0) {
						let str = is_show == 1 ? '已关闭' : '已启用'
						console.log(str)
						t.$message.success(str,1.5)
					    t.getParkList()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.reIng = false
				})
			},
            onEnd() {
                let arr = this.list.map(item => {
                    return item.park_id
                })
                this.$post('parkOrder',{orderList: arr})
            },
 		}
	}
</script>

<style lang="less">
	.head-img-box {
		width: 100%;
		max-height: 120px;
		img{
			width: 100%;
			height: 100%;
			display: block;
			object-fit: contain;
		}
	}
	
	
</style>
